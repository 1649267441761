<template>
  <div class="all">
    <div class="content">
      <el-card>
        <div style="text-align: center">
          <h3>用户登录</h3>
        </div>
        <el-form
          :model="loginForm"
          status-icon
          :rules="rules"
          ref="loginForm"
          label-width="65px"
        >
          <el-form-item label="公司" prop="client_id">
            <el-input
              placeholder="公司"
              v-model="loginForm.client_id"
              prefix-icon="el-icon-house"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="userName">
            <el-input
              placeholder="用户名"
              v-model="loginForm.userName"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              placeholder="密码"
              type="password"
              v-model="loginForm.password"
              prefix-icon="el-icon-key"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="login"
              :loading="loading"
              style="width: 100%"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <!-- <div class="foot">©{{new Date|datetime('YYYY')}} 致信恒达 版权所有</div>-->
    <div class="foot">
      <el-popover placement="top-start" width="200" trigger="hover">
        <el-image
          style="width: 200px; height: 260px"
          src="/img/bai.jpg"
        ></el-image>
        <span slot="reference">客服联系：15940516473（白）</span>
      </el-popover>
      <div>
        致信恒达 版权所有
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >辽ICP备19009532号-2</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      loginForm: {
        client_id: localStorage["company"],
        userName: "",
        password: "",
      },
      rules: {
        client_id: [{ required: true, message: "请输入公司名" }],
        userName: [{ required: true, message: "请输入用户名" }],
        password: [{ required: true, message: "请输入密码" }],
      },
      loading: false,
    };
  },
  methods: {
    login() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$post("token", {
            grant_type: "password",
            ...this.loginForm,
          })
            .then((r) => {
              localStorage["company"] = this.loginForm.client_id;
              sessionStorage["token"] = r.access_token;
              this.$store.dispatch("GetUserInfo");
              this.$store.dispatch("GetBaseInfo");
              this.$store.dispatch("AddMenus");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  height: 100vh;
  background: linear-gradient(-45deg, #409eff, rgb(245, 245, 245));
  position: relative;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  width: 400px;
}
.foot {
  font-weight: 900;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
}
</style>